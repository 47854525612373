@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  background-color: #01263c;
}
/*Header*/

.App {
  text-align: center;
  background-color: #01263c;
}

.logoImg {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: row;
  margin: 0 2%;
  margin-right: 10%;
}

.logo {
  font-size: 60px;
  color: #c0c0c0;
  font-family: "Great Vibes", cursive;
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flags {
  width: auto;
  height: auto;
  position: static;
}

.allFlags {
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #015981;
  min-height: 100%;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: 015981;
  margin: 3%;
  padding: 2%;
  border-radius: 5px;
}

.App-link {
  color: #c0c0c0;
}

/*Manu*/

.wholemanu {
  display: flex;
  flex-direction: row;
}

.manuTabs {
  width: 33%;
  background-color: #c0c0c0;

  font-size: 20px;

  margin: 0% 3%;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #015981;
}

/*Photos*/

.slider {
  background-color: 015981;
  margin: 3%;
  border-radius: 5px;
  height: 100%;
  padding-top: 2%;
}

.simpleSlider {
  font-weight: bold;
  color: #c0c0c0;
  margin-bottom: 2%;
}

.simpleSliderContact {
  color: #01263c;
  font-weight: bold;

  margin-bottom: 2%;
}

img {
  margin: auto;
  border-radius: 3px;
  width: 1450px;
  height: 720px;
}

h2 {
  padding-top: -10%;
  font-family: "Great Vibes", cursive;
  font-weight: bolder;
}

.tabs {
  background: #67eaca;
  width: 94%;
  margin-left: 3%;
  font-family: "Great Vibes", cursive;
  font-weight: bold;
}

#photos {
  margin: auto;
  width: 95%;
}

.homePageSlider {
  border-radius: 6px;
}
/*About*/

.about {
  margin: 3%;
  background-color: white;
  padding: 2%;
}

.aboutLetters {
  color: white;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 3%;
  margin-right: 3%;
  list-style-type: none;
}
.abouth2 {
  padding-top: 2%;
  padding-bottom: 3%;
  font-weight: bold;
  color: white;
}

.aboutParagraphs {
  justify-content: left;
  color: white;
}

.map {
  width: 49%;
  height: 342px;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 0.5%;
}

.nav {
  margin-left: 3%;
  margin-right: 3%;
}

.li {
  position: relative;
  color: white;
}

.a {
  color: white;
}

/* .nav-tabs {
  background-color: #c0c0c0;
} */

/*Social Media*/

.socialmedia {
  height: auto;
  width: 93%;
  margin: 3% 0;
}

/*Contact*/
.form {
  background: white;
  width: 94%;
  margin-left: 3%;
  /* font-family: "Great Vibes", cursive; */
  padding-top: 3%;
  padding-bottom: 3%;
  margin-bottom: 3%;
  border-radius: 5px;
  background-color: #c0c0c0;
}

.name {
  display: block;
  float: left;
  padding-top: 2%;
  width: 100%;
}

.email {
  display: block;
  padding-top: 2%;
}

.textarea {
  display: block;
  padding-top: 2%;
  margin-left: 45%;
  margin-top: 2%;
}

.submit {
  margin-top: 2%;
}

/*Footer*/

.footer {
  background-color: #015981;
  display: flex;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  margin: 3%;
  padding: 1%;
  border-radius: 5px;
  font-family: "Great Vibes", cursive;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive design */

@media only screen and (max-width: 600px) {
  .logoImg {
    margin-left: 7%;
  }

  .logo {
    margin-left: -7%;
    font-size: xx-large;
  }

  .wholemanu {
    display: flex;
    flex-direction: column;
  }

  .manuTabs {
    margin-top: 1%;
    margin-bottom: 1%;
    width: auto;
  }

  .map {
    width: 100%;
    height: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}
